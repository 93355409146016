import store from '@/store'
import router from '@/router'
import axios from './Axios'
import $Dialog from './Dialog'
import i18n from '@/i18n'

const model = {
  GetBackData(callback) {
    const getData = ({ data }) => {
      store.dispatch('UpdateInitData', data.info || '');
      // localStorage['UploadApi'] = data.info.setting.up_url;
      callback && callback(data.info);
    }
    axios.post('Common/BackData', '', { noLogin: true }).then(getData);
  },
  GetCoinRates(callback) {
    const getData = ({ data }) => {
      store.dispatch('UpdateCoinRates', data.info || '');
      // localStorage['UploadApi'] = data.info.setting.up_url;
      callback && callback(data.info);
    }
    axios.post('Common/CoinRates', '', { noLogin: true }).then(getData);
  },
  Login(json, callback) {
    console.log(json, callback)
    const getData = ({ data }) => {
      if (data && data.code == 1) {
        localStorage['Token'] = data.info.token;
        localStorage['UserId'] = data.info.userid;
        store.dispatch('UpdateUserInfo', data.info);
        router.replace('/');
        // if(localStorage['FromPage']){
        //   router.replace(localStorage['FromPage']);
        //   localStorage.removeItem('FromPage');
        // }else{
        //   router.replace('/');
        // }
      }
      callback && callback(data);
      // $Dialog.Toast(data.code_dec);
    }
    axios.post('User/login', json, { noLogin: true }).then(getData);
  },
  Logout(callback) {
    const getData = ({ data }) => {
      if (data.code == 1) {
        localStorage.removeItem('Token');
        localStorage.removeItem('UserInfo');
        store.dispatch('UpdateUserInfo', '');
        this.GetBackData();
        router.replace('/login');
      }
      $Dialog.Toast(data.code_dec);
    }
    axios.post('User/Logout').then(getData);
  },
  ResetPwd(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    };
    axios.post("User/resetPassword", json).then(getData);
  },
  UserRegister(json, callback) {
    $Dialog.Loading(i18n.t('dialog[5]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        model.Login({ username: json.username, password: json.password })
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('user/Register', json, { noLogin: true }).then(getData);
  },
  SmsCode(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('sms/smsCode', '', { noLogin: true }).then(getData);
  },
  GetSMSCode(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('sms/sendSMSCode', json, { noLogin: true }).then(getData);
  },
  GetUsdtAddress(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Recharge/requestUsdtAddress").then(getData);
  },
  GetUserInfo(callback) {
    const getData = ({ data }) => {
      if (data.code == 1) {
        store.dispatch('UpdateUserInfo', data.info);
      }
      callback && callback(data);
    }
    axios.post('user/getUserInfo').then(getData);
  },
  SetUserInfo(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('user/setuserinfo', json).then(getData);
  },
  CreateOrder(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        router.push('/user/invest');
        this.GetUserInfo();
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('Order/createOrder', json).then(getData);
  },
  OrderList(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Order/Orderlist').then(getData);
  },
  NftList(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('/nft/getlist').then(getData);
  },
  OrderRecordList(id, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Order/orderRecordList', { orderid: id }).then(getData);
  },
  FundDetails(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Transaction/FundDetails', json).then(getData);
  },
  GetDrawRecord(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Transaction/getDrawRecord', json).then(getData);
  },
  Draw(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('Transaction/draw', json).then(getData);
  },
  Transfer(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('Transaction/Transfer', json).then(getData);
  },
  AddBankCard(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetBankCardList()
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('Account/AddBankCard', json).then(getData);
  },
  GetBankCardList(callback) {
    const getData = ({ data }) => {
      store.dispatch('UpdateBankCardList', data.data || []);
      callback && callback(data);
    }
    axios.post('Account/getBankCardList').then(getData);
  },
  GetRechargeRecord(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Transaction/getRechargeRecord', json).then(getData);
  },
  GetRechargeType(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Transaction/getRechargetype', { type: 'app' }).then(getData);
  },
  RechargeOrder(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        router.push('/user/recharge/' + data.orderNumber);
      }
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('Recharge/newRechargeOrder', json).then(getData);
  },
  GetRechargeInfo(orderId, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Recharge/getRechargeInfo', { orderNumber: orderId }).then(getData);
  },
  SetOrderInfo(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('Recharge/setOrderInfo', json).then(getData);
  },
  UploadImg(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('User/UploadImg', json, { fromData: true, diyApi: true }).then(getResponse);
  },
  BuyVip(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getResponse = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('User/userBuyVip', json).then(getResponse);
  },
  PostTask(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getResponse = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('task/publishTask', json).then(getResponse);
  },
  GetTaskList(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('task/getTaskList', json).then(getResponse);
  },
  ReceiveTask(id, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getResponse = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('task/receiveTask', { id: id }).then(getResponse);
  },
  GetTaskinfo(id, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('task/getTaskinfo', { id: id }).then(getResponse);
  },
  GetTaskRecord(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('task/taskOrderlist', json).then(getResponse);
  },
  SubmitTask(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getResponse = ({ data }) => {
      callback && callback(data);
      // $Dialog.Toast(data.code_dec);
    }
    axios.post('usertask/submitTask', json).then(getResponse);
  },
  CancelTask(id, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getResponse = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('task/revokeTask', { id: id }).then(getResponse);
  },
  TaskOrderInfo(id, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('task/taskorderinfo', { order_id: id }).then(getResponse);
  },
  AuditTask(json, callback) {
    $Dialog.Loading(i18n.t('dialog[2]'));
    const getResponse = ({ data }) => {
      callback && callback(data);
      $Dialog.Toast(data.code_dec);
    }
    axios.post('task/taskOrderTrial', json).then(getResponse);
  },
  DailyReport(callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('User/dailyReport').then(getResponse);
  },
  CreditList(callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('user/getUserCreditList').then(getResponse);
  },
  TeamReport(json, callback) {
    $Dialog.Loading(i18n.t('dialog[6]'));
    const getResponse = ({ data }) => {
      $Dialog.Close();
      callback && callback(data);
    }
    axios.post('user/teamReport', json).then(getResponse);
  },
  GetStatisticsInfo(callback) {
    const getResponse = ({ data }) => {
      if (data.code == 1) {
        store.dispatch('UpdateStatisticalData', data.info);
      }
      callback && callback(data);
    }
    axios.post('user/getStatisticsInfo').then(getResponse);
  },
  GetPayBank(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    }
    axios.post('Account/GetPayBankCode', json).then(getResponse);
  },
}

export default model
