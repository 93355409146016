const tab = {
  text: [
    'Home',
    'Football',
    'Betting Slip',
    'Betting Records'
  ]
}
const dialog = [
  "Prompt",
  "Confirm",
  "Submitting...",
  "Copy Successful",
  "IOS system version too low to support",
  "Registering...",
  "Loading data...",
  "No more."
]

const index = {
  title: 'Early Markets',
  title2: 'Live Betting',
  zuqiu: 'Football',
  Alltime: 'All Time',
  timeAndPlay: 'Time/League'
}
const login = {
  accont: 'Login Account',
  phone: 'Phone Number',
  mail: 'Email',
  pwd: 'Password',
  placeholder1: 'Please enter phone number', //'
  placeholder_m: 'Please enter email', //'
  placeholder2: 'Please enter account', // 'Please enter your account',
  placeholder3: 'Please enter password', // 'Please enter a password',
  placeholder4: 'Please enter verification code', // 'Please enter a password',
  placeholder5: 'Please enter invitation code', // 'Please enter the recommendation code'
  forgotPwd: 'Forgot Password?',
  forgotPwd1: 'Forgot Password',
  Reset_password: 'Reset Password',
  login: 'Login',
  Dont_have_an_account_yet: 'Don’t have an account yet?',
  yklogin: 'Guest Login',
  zxkf: 'Online Customer Service',
  remember: 'Remember my account',
  Register_now: 'Register Now',
  Login_now: 'Go to Login',
  Register: 'Register',
  VerificationCode: 'Verification Code',
  ConfirmPassword: 'Confirm Password',
  getCode: 'Get Code',
  send: 'Sent',
  Referral_Code: 'Invitation Code',
  by: 'By clicking register, I agree to the',
  User_Agreement: 'User Agreement',
  submit: 'Confirm',

  read1: 'I have read and agree to',
  read2: 'the relevant terms',
  read3: 'and',
  read4: 'Privacy Policy',
}

const lang = {
  text: 'Language',
}

const recharge = {
  rechargeLog: 'Recharge Records',
  title: 'USDT Recharge Page',
  money: 'Amount',
  btn1: 'Scan the QR code to recharge and submit',
  recharge: 'Recharge',
  ERC20: 'ERC20',
  TRC20: 'TRC20',
  OMNI: 'OMNI',
  text: [
    'This address can only deposit USDT, deposits other than USDT are invalid and non-refundable',
    'TKQeybLDtotxGMxaEpROWgESjTtvGqH98L'
  ],
  copy: 'Copy',
  label: [
    "Category",
    "Address",
    "Recharge Amount",
    "Enter Amount",
    "Upload Voucher",
    "Copy Successful",
    "Pre-recharge Exchange"
  ],
  default: [
    "Wallet recharge",
    "Wallet pre-recharge",
    "Recharge method",
    "Recharge",
    "Confirm",
  ],
  coinRecharge: [
    'Virtual Currency Recharge', 'Currency Type', 'Recharge Address', "Recharge Address QR Code"
  ],
  balanceNotEnough:'Balance not enough',
  balanceTip:"go to recharge please!"
}

const withdraw = {
  withdrawLog: 'Withdrawal Records',
  withdraw: 'Withdraw',
  AccountBalance: 'Account Balance',
  FreezeBalance: 'Frozen Balance',
  WithdrawalAddress: 'Withdrawal Address',
  ChainName: 'Chain Name',
  WithdrawalAmount: 'Withdrawal Amount',
  TransactionPassword: 'Transaction Password',
  p1: 'Please enter withdrawal address',
  p2: 'Please enter withdrawal amount',
  p3: 'Please enter transaction password',
}

const my = {
  text: [
    'Account History',
    'Quota Modification Records',
    'Language',
    'Betting Type',
    'Change Password',
    'Easy Password Login',
    'Password Recovery',
    'FAQs',
    'Odds Conversion',
    'System Requirements',
    'Rules and Terms',
    'Contact Us',
  ],
  text1: [
    'Activate'
  ],
  setting: 'Settings',
  help: 'Help',
  logout: 'Logout'
}


const zuqiu = {
  text: [
    'Time',
    'League',
    'Event Filter',
    'Handicap',
    'Over/Under',
  ],
  wanfa: [
    'Live Betting',
    'Early Markets',
  ],
  qiu: [
    'Football',
    'Basketball',
    'Esports Football',
    'Tennis',
    'Badminton',
  ],
  type: [
    'Main Bet Types',
    'Handicap & Over/Under',
    'Corner Kicks',
    'Card Count',
    'Correct Score',
    'Goal Scorers',
  ],
  text1: [
    'Handicap',
    'Over/Under',
    'Straight Win',
  ],
  big: 'Over',
  small: 'Under',
  text2: [
    'Home',
    'Away',
    'Draw',
  ],
  zhudan: 'Betting Slip',
  xiazhu: 'Place Bet',
  month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  month_s: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',],
}

const order = {
  type: [
    'Transaction Status',
    'Fund Movement Records',
  ],
  allTy: 'All Sports',
  money1: 'Bet Amount',
  money2: 'Potential Win Amount',
  all: 'Total',
  removeAll: 'Are you sure you want to remove all bets?',
  table_title: [
    'Date',
    'Bet Amount',
    'Valid Amount',
    'Win/Loss',
  ],
  tzlog:["League","Match","Order Number","Bet Amount","Bet",
    "Type","Odds","Prize","Status","Bet Time"],

}

const changePwd = {
  text: [
    'For security reasons, you must change your password every 30 days.',
    'Your password must follow these rules:',
    '1. At least two uppercase or lowercase letters and numbers (0-9), with a minimum length of 6 to 12 characters.',
    '2. Three different letters and numbers.',
    '3. No spaces allowed.',
  ],
  changePwd: 'Change Password',
  now_pwd: 'Current Password',
  new_pwd: 'New Password',
  sure_pwd: 'Confirm Password',
  btn1: 'Cancel',
  btn2: 'Submit',
}

const simplePwd = {
  simplePwd: 'Simple Password Login',
  text: [
    'Enter your simple password',
    'Set your simple password for quick login.',
    'Close',
    'Delete',
  ]
}

const resetPwd = {
  'resetPwd': 'Password Recovery',
  'text': [
    'Please enter your email address used for password recovery.',
    'Email Address',
    'Cancel',
    'Next Step',
  ]
}

const question = {
  question: 'Frequently Asked Questions',
  time: 'Last Updated Date:'
}

const odds = {
  odds: 'Odds Conversion',
  table_title1: [
    'Betting Type',
    'Bet Amount',
    'Odds',
    'Win',
    'Loss',
  ],
  title: 'Odds Conversion Table',
  table_title2: [
    'Hong Kong Odds',
    'Malaysian Odds',
    'Indonesian Odds',
  ]
}

const system = {
  system: 'System Requirements',
  text1: [
    'Mobile Operating System',
    'Operating System',
    'Requirement',
    'Android',
    'Android 7 or above',
    'iOS',
    'iOS 12 or above',
  ],
  text2: [
    'Mobile Browser',
    'Android Browser',
    'Requirement',
    'Chrome',
    'Version 87 or above',
    'QQ Browser',
    'Version 10 or above',
  ],
  text3: [
    'iOS Browser',
    'iOS Browser',
    'Requirement',
    'Safari',
    'Version 11 or above',
    'Chrome',
    'Version 84 or above',
  ],
  text4: [
    'Desktop Operating System',
    'Operating System',
    'Requirement',
    'Windows',
    'Windows 7 or 10',
    'Mac OS',
    'OS X10.10 or above',
  ],
  text5: [
    'Desktop Browser',
    'Windows Browser',
    'Requirement',
    'Chrome',
    'Version 85 or above',
    'Firefox',
    'Version 60 or above',
  ],
  text6: [
    'Unsupported Browsers and Operating Systems',
    'To provide the best user experience, we maintain a short list of supported browsers and operating systems. If bugs or issues arise in unsupported browsers or operating systems, we reserve the right not to fix them. If you choose to use unsupported browsers or operating systems, you may encounter performance or display issues.'
  ]
}

const type = {
  type: [
    'All',
    'In Progress',
    'Failed',
    'Successful',
  ]
}

const rule = {
  rule: 'Rules and Terms',
  text1: [
    'Terms of Use',
    '1. The use of services provided by this company is at the discretion of the customer, and the risks are borne by the customer. By participating in our services, the customer acknowledges that the services provided by this company are normal, reasonable, fair, and just.<br><br>2. Certain legal rules in the jurisdiction where you reside may not explicitly state whether online or offline gambling is allowed. Customers are responsible for ensuring that their gambling activities are legal in their jurisdiction at all times.'
  ],
  text2: [
    'Betting Acceptance Conditions',
    '1. The use of services provided by this company is at the discretion of the customer, and the risks are borne by the customer. By participating in our services, the customer acknowledges that the services provided by this company are normal, reasonable, fair, and just.<br><br>2. Certain legal rules in the jurisdiction where you reside may not explicitly state whether online or offline gambling is allowed. Customers are responsible for ensuring that their gambling activities are legal in their jurisdiction at all times.'
  ],
  text3: [
    'Disclaimer',
    '1. The use of services provided by this company is at the discretion of the customer, and the risks are borne by the customer. By participating in our services, the customer acknowledges that the services provided by this company are normal, reasonable, fair, and just.<br><br>2. Certain legal rules in the jurisdiction where you reside may not explicitly state whether online or offline gambling is allowed. Customers are responsible for ensuring that their gambling activities are legal in their jurisdiction at all times.'
  ],
}

const contactUs = {
  contactUs: 'Contact Us'
}

const tzLog = {
  type: [
    'Unsettled',
    'Settled',
    'Scheduled Bet',
  ]
}
const detail = {
  text: [
    'No live broadcasts'
  ],
  type: [
    'All',
    'Popular',
    'Handicap & Over/Under',
    'Correct Score',
    'Goals',
    'Special',
  ]
}
export {
  detail,
  tzLog,
  dialog,
  withdraw,
  tab,
  index,
  login,
  lang,
  recharge,
  my,
  zuqiu,
  order,
  changePwd,
  simplePwd,
  resetPwd,
  question,
  odds,
  system,
  rule,
  contactUs,
  type
}

